<template>
  <layout :showTopBanner="false" :showSidebar="false">
    <app-header :showRightButtons="false" :leftLogoPersist="true"></app-header>
    <v-card class="container" rounded="xl">
      <h1>Welcome back</h1>
      <Quote />
      <div v-if="appMode !== 'enterprise'">
        <form @submit.prevent="submit">
          <div v-if="this.error" class="error-message">{{ error }}</div>
          <div class="form-item">
            <v-text-field
              type="text"
              label="Email or Phone number"
              :hint="usernameHint"
              v-model.trim="username"
              :error-messages="usernameInvalid"
            />
          </div>
          <div class="form-item">
            <v-text-field
              name="password"
              label="Password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              v-model="password"
              @click:append="showPassword = !showPassword"
              :error-messages="passwordInvalid"
              autocorrect="off"
              autocapitalize="off"
              spellcheck="false"
            />
          </div>
          <div class="submit-button">
            <inqli-button width="100%" :isProcess="isProcessing" type="submit"
              >Sign in</inqli-button
            >
          </div>
        </form>
        <div class="form-link">
          <a href="/forgot-password">Forgot password</a>
          <p>
            Don't have an account.
            <a
              href="https://inqli.notion.site/inqli/Building-a-Mentorship-Community-529da98138b149cdad090ced6c0d31d3"
              target="_blank"
              >Click here</a
            >
            to join Inqli Beta.
          </p>
        </div>
      </div>
      <div class="oauth-button mt-12" v-if="appMode === 'enterprise'">
        <inqli-button width="100%" @click="signInSSO">
          Sign in with SSO
        </inqli-button>
      </div>
    </v-card>
  </layout>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import Header from "../app/components/headers/DefaultHeader.vue";
import Quote from "../app/components/Quote.vue";
import Layout from "../app/components/layouts/DefaultLayout.vue";
import Button from "../app/components/buttons/Button";
import { isValidUsername } from "../app/utils/validation-helper";
import sendRequest from "../app/utils/send-request-helper";
import isApplicableDomain from "../app/utils/applicable-domain-helper";
import { Auth } from "aws-amplify";

export default {
  components: {
    appHeader: Header,
    Quote,
    layout: Layout,
    "inqli-button": Button,
  },
  metaInfo: {
    title: "Sign in",
  },
  data() {
    return {
      username: "",
      password: "",
      error: null,
      submitStatus: "",
      usernameInvalid: "",
      passwordInvalid: "",
      showPassword: false,
      isProcessing: false,
    };
  },
  validations: {
    username: {
      required,
      isValidUsername,
    },
    password: {
      required,
    },
  },
  computed: {
    isPhoneNumber() {
      const val = this.username;
      return !isNaN(val) || (val.includes("+") && !isNaN(val.substring(1)));
    },
    usernameHint() {
      if (this.isPhoneNumber) {
        return "Phone number must have country code, no spaces or dashes.";
      }
      return "";
    },
    appMode() {
      const mode = process.env.VUE_APP_MODE;
      return mode.toLowerCase();
    },
  },
  watch: {
    username(val) {
      this.username = val.trim().toLowerCase();
      this.validateUsername();
    },
    password() {
      this.validatePassword();
    },
  },
  methods: {
    async submit() {
      if (!this.isProcessing) {
        this.isProcessing = true;
        this.error = "";
        if (!this.validate()) {
          return;
        }
        try {
          const signInSuccess = await this.signIn();
          if (signInSuccess) {
            // load user info
            await this.$store.dispatch("loadUserInfo");
            // check user email
            const userEmail = await this.checkUserEmail();
            if (userEmail) {
              // check user org
              const userOrg = await this.checkUserOrg();
              if (userOrg) {
                this.$router.push(`/users/${this.$store.state.user.username}`);
              } else {
                // check if user email has the same domain from existing org
                const emailDomain = userEmail.split("@")[1].trim();
                const org = await this.checkOrgByDomain(emailDomain);
                if (org) {
                  this.$router.push(`/organizations/join`);
                } else if (isApplicableDomain(emailDomain)) {
                  // check user domain
                  this.$router.push(`/organizations/create`);
                } else {
                  this.$router.push(
                    `/users/${this.$store.state.user.username}`
                  );
                }
              }
            } else {
              this.$router.push("/email-prompt");
            }
          }
        } catch (e) {
          // if system admin does not have user info => go to user list
          if (this.$store.state.user.isSystemAdmin) {
            this.$router.push("/system-admin/users");
          } else {
            // if error occurs => go to user details
            this.$router.push(`/users/${this.$store.state.user.username}`);
          }
        }
        this.isProcessing = false;
      }
    },
    async checkOrgByDomain(domain) {
      const data = await sendRequest({
        url: `/resources/organizations/domain/${domain}`,
        method: "GET",
        isAuth: true,
      });
      return data;
    },
    async signIn() {
      try {
        if (this.validate()) {
          this.username = this.formatUsernameForPhone(this.username);
          await this.$store.dispatch("login", {
            username: this.username,
            password: this.password,
          });
        }
        return true;
      } catch (error) {
        this.error = "Invalid email or password.";
        return false;
      }
    },
    async checkUserOrg() {
      await this.$store.dispatch("loadUserOrganization");
      const orgs = this.$store.state.user.organizations;
      return Array.isArray(orgs) && orgs.length > 0;
    },
    async checkUserEmail() {
      let userInfo = this.$store.state.user.info;
      // if user info does not exist =>
      if (!userInfo) {
        await this.$store.dispatch("loadUserInfo");
        userInfo = this.$store.state.user.info;
      }
      // email exists in user profile
      if (userInfo.email) {
        return userInfo.email;
        // email does not exists in user profile but using email to login => update user's email attribute
      } else if (!this.isPhoneNumber) {
        const updatedUserInfo = await sendRequest({
          url: `/resources/users/${this.$store.state.user.username}`,
          method: "PUT",
          isAuth: true,
          body: {
            email: this.username,
          },
        });
        this.$store.commit("setUserInfo", updatedUserInfo);
        return this.username;
      } else {
        const userAmplifyInfo = await Auth.currentUserInfo();
        const email = userAmplifyInfo.attributes.email;
        if (email && userAmplifyInfo.attributes["email_verified"]) {
          const updatedUserInfo = await sendRequest({
            url: `/resources/users/${this.$store.state.user.username}`,
            method: "PUT",
            isAuth: true,
            body: {
              email,
            },
          });
          this.$store.commit("setUserInfo", updatedUserInfo);
          return email;
        }

        return false;
      }
    },
    formatUsernameForPhone(val) {
      if (this.isPhoneNumber && !val.includes("+")) {
        return val.length <= 10 ? `+1${val}` : `+${val}`;
      }
      return val;
    },
    validateUsername() {
      if (this.$v.username.$invalid) {
        if (!this.$v.username.required) {
          this.usernameInvalid = "Please enter your email or phone number.";
        } else if (!this.$v.username.isValidUsername) {
          this.usernameInvalid = "Please enter a valid email or phone number.";
        }
      } else {
        this.usernameInvalid = "";
      }
    },
    async signInSSO() {
      try {
        Auth.federatedSignIn({
          customProvider: process.env.VUE_APP_AWS_SSO_IDENTITY_PROVIDER,
        });
      } catch (e) {
        console.log(e);
      }
    },
    validatePassword() {
      if (this.$v.password.$invalid) {
        this.passwordInvalid = "Password is required.";
      } else {
        this.passwordInvalid = "";
      }
    },
    validate() {
      if (this.$v.$invalid) {
        this.validateUsername();
        this.validatePassword();
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  max-width: 500px;
  width: 60%;
  flex: 1;
  margin: 0 auto;
  margin-top: 45px;
  padding: 35px 64px;
  .error-message {
    color: grey;
    color: $inqliRed;
    line-height: 24px;
    margin-bottom: 10px;
  }
  .message {
    font-weight: bold;
    font-size: 18px;
  }
  .form-item {
    margin-bottom: 0px;
    padding: 0;
  }
  .form-title {
    margin-bottom: 20px;
  }
  .submit-button {
    margin-top: 15px;
    margin-bottom: 25px;
  }
  .form-link {
    display: block;
    font-size: 13px;
    p {
      font-size: 13px;
      margin-top: 10px;
    }
  }
}
.oauth-button {
  margin-top: 15px;
}
@media screen and (max-width: $breakpoint-mobile) {
  .container {
    width: 95%;
    padding: 30px 26px;
    margin-top: 20px;
    margin-bottom: 30px;
  }
}
</style>

<template>
  <div class="mx-5 quote-container">
    <p>{{ '"' + quote.quote + '"' }}</p>
    <p>{{ "-" + quote.cite }}</p>
  </div>
</template>

<script>
export default {
  name: "Quote",
  data() {
    return {
      quote: {
        quote: "",
        cite: "",
      },
      quotes: [
        {
          quote:
            "One of my most productive days was throwing away 1,000 lines of code.",
          cite: "Ken Thompson",
        },
        {
          quote:
            "The delicate balance of mentoring someone is not creating them in your own image, but giving them the opportunity to create themselves.",
          cite: "Steven Spielberg",
        },
        {
          quote:
            "Education is what remains after one has forgotten everything they learned in school.",
          cite: "Albert Einstein",
        },
        {
          quote:
            "You cannot teach a man anything. You can only help him discover it within himself.",
          cite: "Galileo Galilei",
        },
        {
          quote:
            "I’ve learned that people will forget what you said, people will forget what you did, but people will never forget how you made them feel.",
          cite: "Maya Angelou",
        },
      ],
    };
  },
  mounted() {
    const index = Math.floor(Math.random() * this.quotes.length);
    this.quote = this.quotes[index];
  },
};
</script>
<style lang="scss" scoped>
.quote-container {
  margin-top: 20px;
  p {
    font-size: 12px;
    font-style: italic;
    margin-top: -10px;
    color: $inqliGray;
  }
}
</style>

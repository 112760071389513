var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    { attrs: { showTopBanner: false, showSidebar: false } },
    [
      _c("app-header", {
        attrs: { showRightButtons: false, leftLogoPersist: true }
      }),
      _c(
        "v-card",
        { staticClass: "container", attrs: { rounded: "xl" } },
        [
          _c("h1", [_vm._v("Welcome back")]),
          _c("Quote"),
          _vm.appMode !== "enterprise"
            ? _c("div", [
                _c(
                  "form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.submit.apply(null, arguments)
                      }
                    }
                  },
                  [
                    this.error
                      ? _c("div", { staticClass: "error-message" }, [
                          _vm._v(_vm._s(_vm.error))
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "form-item" },
                      [
                        _c("v-text-field", {
                          attrs: {
                            type: "text",
                            label: "Email or Phone number",
                            hint: _vm.usernameHint,
                            "error-messages": _vm.usernameInvalid
                          },
                          model: {
                            value: _vm.username,
                            callback: function($$v) {
                              _vm.username =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "username"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "form-item" },
                      [
                        _c("v-text-field", {
                          attrs: {
                            name: "password",
                            label: "Password",
                            "append-icon": _vm.showPassword
                              ? "mdi-eye"
                              : "mdi-eye-off",
                            type: _vm.showPassword ? "text" : "password",
                            "error-messages": _vm.passwordInvalid,
                            autocorrect: "off",
                            autocapitalize: "off",
                            spellcheck: "false"
                          },
                          on: {
                            "click:append": function($event) {
                              _vm.showPassword = !_vm.showPassword
                            }
                          },
                          model: {
                            value: _vm.password,
                            callback: function($$v) {
                              _vm.password = $$v
                            },
                            expression: "password"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "submit-button" },
                      [
                        _c(
                          "inqli-button",
                          {
                            attrs: {
                              width: "100%",
                              isProcess: _vm.isProcessing,
                              type: "submit"
                            }
                          },
                          [_vm._v("Sign in")]
                        )
                      ],
                      1
                    )
                  ]
                ),
                _c("div", { staticClass: "form-link" }, [
                  _c("a", { attrs: { href: "/forgot-password" } }, [
                    _vm._v("Forgot password")
                  ]),
                  _c("p", [
                    _vm._v(" Don't have an account. "),
                    _c(
                      "a",
                      {
                        attrs: {
                          href:
                            "https://inqli.notion.site/inqli/Building-a-Mentorship-Community-529da98138b149cdad090ced6c0d31d3",
                          target: "_blank"
                        }
                      },
                      [_vm._v("Click here")]
                    ),
                    _vm._v(" to join Inqli Beta. ")
                  ])
                ])
              ])
            : _vm._e(),
          _vm.appMode === "enterprise"
            ? _c(
                "div",
                { staticClass: "oauth-button mt-12" },
                [
                  _c(
                    "inqli-button",
                    { attrs: { width: "100%" }, on: { click: _vm.signInSSO } },
                    [_vm._v(" Sign in with SSO ")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }